import "datatables.net-bs4/css/dataTables.bootstrap4.css";
import "datatables.net-bs4/js/dataTables.bootstrap4.min.js";
import 'datatables.net-buttons-bs4';
import 'datatables.net-buttons/js/buttons.colVis.min';
import 'datatables.net-buttons/js/dataTables.buttons.min';
import 'datatables.net-buttons/js/buttons.flash.min';
import 'datatables.net-buttons/js/buttons.html5.min';
import "@/assets/sass/datatables.custom.scss";
import 'datatables.net-buttons/js/buttons.colVis';
import 'datatables.net-responsive-bs4/js/responsive.bootstrap4';
import dataTablesTurkish from '@/assets/js/functions/datatablesTurkish.json';

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import jsZip from 'jszip';
window.JSZip = jsZip

import $ from "jquery";

$.extend( true, $.fn.dataTable.defaults, {
  lengthMenu: [
    [10, 25, 50, -1],
    ["10 Adet", "25 Adet", "50 Adet", "Tümü"],
  ],
  dom: '<"data-table-top"Bf>t<"data-table-bottom"l<"data-table-info"i>p><"clear">',
  language:{
    ...dataTablesTurkish
  },
  stateSave: true,
  scrollX: true,
} );